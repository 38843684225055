import { useContext } from 'react'
import {
  Avatar, Empty, Pagination, Tooltip,
} from 'antd'

import classNames from 'utils/classNames'
import dateStr from 'utils/date'
import serializeText from 'utils/serializeText'
import Context from '../../Context'

const NotesList = () => {
  const {
    notes,
    setContent,
    noteSelected,
    setNoteSelected,
    setNoteToModify,
    params,
    setParams,
  } = useContext(Context)
  if (!notes.data?.length) {
    return <Empty />
  }
  return (
    <div className='flex h-full w-full'>
      <div className='flex flex-col justify-between w-full'>
        <div className='w-full space-y-2'>
          {notes.data?.map((note) => (
            <div
              className='rounded-lg hover:border-slate-100 px-4 py-2 cursor-pointer space-y-1 hover:bg-blue-100 group'
              onClick={() => {
                setNoteSelected(note)
                setNoteToModify(null)
                setContent(note.content)
              }}
              id={note._id}
              key={note._id}
            >
              <div className='flex items-center space-x-2 w-full'>
                {note._author && (
                  <Tooltip title={`${note._author?.firstName} ${note._author?.lastName}`}>
                    <Avatar src={note._author?.picture} />
                  </Tooltip>
                )}
                <div className='leading-5'>
                  <div className={classNames('truncate font-medium group-hover:text-blue-500', noteSelected?._id === note._id && 'text-blue-500')}>
                    {note._template?.name}
                  </div>
                  <div className='text-xs'>
                    {dateStr(note._createdAt)}
                  </div>
                </div>
              </div>
              <div className='truncate text-slate-400 leading-8'>
                {note.content?.length
                  ? serializeText(note.content)
                  : '...'
                }
              </div>
            </div>
          ))}
        </div>
        <Pagination
          className='p-2'
          current={notes?.$pagination?.currentIndex}
          size="small"
          defaultPageSize={5}
          total={notes?.$pagination?.totalItems}
          pageSizeOptions={[5, 25, 50, 100]}
          onChange={(page, pageSize) => setParams({
            ...params,
            $pagination: {
              ...params.$pagination,
              currentIndex: page,
              itemsPerPage: pageSize,
            },
          })}
        />
      </div>
    </div>
  )
}

export default NotesList
