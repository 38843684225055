import {
  createContext, useEffect, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Avatar, DatePicker, Row, Spin, Switch, Tag,
} from 'antd'
import {
  DeleteOutlined, LeftOutlined, MenuOutlined, MinusCircleOutlined, PlusCircleOutlined, RightOutlined, ScheduleOutlined, UserOutlined,
} from '@ant-design/icons'

import People from 'components/autocomplete/People'
import classNames from 'helpers/classNames'
import Api from 'services/api'
import Slots from './Slots'
import {
  renderStatus, renderTag, statuses, tags,
} from './renders'
import { dayStyle } from './styles'

export const Context = createContext(null)

const HRDatePicker = ({
  planning, getPlanning, userId, groupId, onDateSelection, loading, showTeam, disabled, currentStart, setCurrentStart, fullAccess,
}) => {
  const [planningState, setPlanningState] = useState([])
  const [visibleInput, setVisibleInput] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [currentHoveredUser, setCurrentHoveredUser] = useState(null)
  const [hoveredUserFromParent, setHoveredUserFromParent] = useState(null)
  const [isAccessibility, setIsAccessibility] = useState(false)
  const containerRef = useRef(null)
  const draggingItem = useRef(null)
  const dragOverItem = useRef(null)

  useEffect(() => {
    if (planning.users?.length) {
      setPlanningState(planning.users)
    }
  }, [planning])

  const handleVisible = async (uId) => {
    try {
      const filteredColleagues = planningState.reduce((acc, u) => {
        if (![uId, userId].includes(u.user._id)) {
          acc.push(String(u.user._id))
        }
        return acc
      }, [])
      await Api.axios.put(`v4/admin/admins/${userId}`, { _hrColleagues: filteredColleagues })
    } finally {
      getPlanning()
    }
  }

  const handleAddVisible = async (planning, refresh, uId = undefined) => {
    try {
      const reducedArray = planning.reduce((acc, u) => {
        if (u.user._id !== userId) {
          acc.push(String(u.user._id))
        }
        return acc
      }, [])
      if (uId) reducedArray.push(uId)
      await Api.axios.put(`v4/admin/admins/${userId}`, { _hrColleagues: reducedArray })
    } finally {
      if (refresh) {
        getPlanning()
        setVisibleInput(false)
      }
    }
  }

  const autoCompleteOptions = planning.allColleagues?.reduce((acc, c) => {
    const found = planningState?.map((u) => u.user._id).includes(c._id)
    if (!found) {
      acc.push({
        label: `${c.firstName} ${c.lastName}`,
        key: c._id,
        value: `${c.firstName} ${c.lastName}`,
      })
    }
    return acc
  }, [])

  const handleDragEnter = (e, position) => {
    if (position === 0) return
    setHoveredIndex(position)
    dragOverItem.current = position
  }

  const handleDragEnd = () => {
    const shallowCopy = planningState.slice()
    const draggingItemContent = shallowCopy[draggingItem.current]
    shallowCopy.splice(draggingItem.current, 1)
    shallowCopy.splice(dragOverItem.current, 0, draggingItemContent)
    draggingItem.current = null
    dragOverItem.current = null
    handleAddVisible(shallowCopy, false)
    setPlanningState(shallowCopy)
    setHoveredIndex(null)
  }

  return (
    <div>
      <Row align="middle" justify="space-between" className="border-b pb-2">
        <div className="text-lg font-bold">
          <ScheduleOutlined />
          <span className="pl-3">Planning</span>
        </div>
        <div className='flex items-center'>
          <span>Accessibilité</span>
          <Switch
            size='small'
            checked={isAccessibility}
            onChange={setIsAccessibility}
            className='ml-2'
          />
        </div>
      </Row>
      {planning.users?.length
        ? (
          <Spin spinning={loading}>
            <div className="planning flex flex-col lg:flex-row w-full lg:px-4 lg:mt-10 select-none">
              <div className="users font-bold min-w-max flex justify-end lg:block">
                <div className="h-[124px]">
                  <DatePicker
                    disabled={loading}
                    onChange={(date) => date ? setCurrentStart(date.startOf('month').format()) : setCurrentStart(dayjs().startOf('week').format())}
                    picker="month"
                    placeholder="Aller en ..."
                    className="text-xl font-bold uppercase hr-datepicker min-w-[200px] my-4 lg:my-0"
                    value={dayjs(currentStart)}
                    format={(value) => value.format('MMMM YY')}
                  />
                </div>
                <div className="pr-5 hidden lg:block" onMouseLeave={() => setHoveredUserFromParent(null)}>
                  {planningState?.map((u, uIndex) => (
                    <div key={u?.user?._id} className={typeof groupId !== 'string' && u?.user?._id === userId ? 'ml-[26px]' : undefined} onMouseOver={u?.user?._id === userId ? () => setHoveredUserFromParent(null) : () => setHoveredUserFromParent(u.user._id)}>
                      {!!hoveredIndex && hoveredIndex === uIndex && !!(uIndex > 0) &&
                      <div className="h-[2px] w-full bg-slate-500 rounded-full transition ease-in-out"></div>
                      }
                      <div
                        className={classNames(
                          'pr-3 group w-min-max rounded-md border-t border-white',
                          u?.user?._id === userId ? 'bg-slate-50' : 'hover:bg-slate-50',
                          currentHoveredUser === u?.user._id && 'bg-slate-50',
                        )}
                        onDragOver={(e) => e.preventDefault()}
                        onDragStart={() => draggingItem.current = uIndex}
                        onDragEnter={(e) => handleDragEnter(e, uIndex)}
                        onDragEnd={() => handleDragEnd(u)}
                      >
                        <div className='flex items-center justify-between pl-1'>
                          <div className="flex items-center">
                            {typeof groupId !== 'string' && uIndex > 0 && (
                              <MenuOutlined
                                className={classNames(
                                  'mr-2 ml-1 text-slate-300 opacity-0 group-hover:opacity-100 cursor-pointer hover:text-slate-600 transition ease-in-out',
                                  currentHoveredUser === u?.user._id && 'opacity-100',
                                )}
                                draggable={!!uIndex > 0}
                              />
                            )}
                            <Avatar size="small" src={u.user?.picture} icon={<UserOutlined />} className='mr-2' />
                            <div className="h-[30px] flex items-center mr-4 min-w-max text-md">{u?.user?.fullName}</div>
                          </div>
                          <div className="w-[20px]">
                            {u?.user?._id !== userId && planningState?.length > 1 && typeof groupId !== 'string' && (
                              <DeleteOutlined
                                className={classNames(
                                  'group-hover:block text-md ml-2 text-slate-400 hover:text-slate-600 transition ease-in-out',
                                  currentHoveredUser === u?.user._id ? 'block' : 'hidden',
                                )}
                                onClick={() => handleVisible(u?.user?._id)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {showTeam && typeof groupId !== 'string' && (
                  <div className="hover:slate-50 hidden lg:flex items-center h-[71px]">
                    {visibleInput
                      ? <MinusCircleOutlined onClick={() => setVisibleInput(false)} className="text-lg transition ease-in-out hover:scale-110" />
                      : <PlusCircleOutlined onClick={() => setVisibleInput(true)} className="text-lg transition ease-in-out hover:scale-110" />
                    }
                    {visibleInput && (
                      <People
                        types={['admin', 'developer']}
                        style={{
                          width: '70%',
                          padding: 8,
                          border: 'none',
                          marginLeft: '1rem',
                        }}
                        options={autoCompleteOptions}
                        filterOption={(_, option) => !planningState?.map((u) => u.user._id).includes(option.value)}
                        dropdownClassName="rounded-md"
                        placeholder="Rechercher..."
                        onSelect={(person) => handleAddVisible(planningState, true, person._id)}
                        clearAfterSelect
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="overflow-x-scroll hr-datepicker-parent-container">
                <div className="flex items-end lg:items-start overflow-x-scroll hr-datepicker-container">
                  <LeftOutlined className="text-2xl cursor-pointer lg:mt-[3.2rem] lg:mr-4 transition ease-in-out hover:scale-110" onClick={() => setCurrentStart(dayjs(currentStart).subtract(7, 'days').format())} />
                  <div className="rounded-md h-fit overflow-x-scroll hr-datepicker-calendar" style={{ height: 'fit-content' }} ref={containerRef}>
                    <div className="flex">
                      {planning.dates?.map((day, index) => (
                        <div key={index}>
                          <div className="flex justify-center items-center flex-col font-bold">
                            <div
                              className={classNames(
                                'h-[62px] flex justify-center items-center uppercase rounded-md border border-white',
                                ['s', 'd'].includes(day.name) ? 'w-[46px]' : 'w-[62px]',
                              )
                              }
                              style={dayStyle(day)}
                            >
                              {day.name}
                            </div>
                            <div
                              className={classNames(
                                'h-[62px] flex justify-center items-center uppercase rounded-md border border-white',
                                ['s', 'd'].includes(day.name) ? 'w-[46px]' : 'w-[62px]',
                              )
                              }
                              style={dayStyle(day)}
                            >
                              {day.dayNumber}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Context.Provider value={{
                      fullAccess, disabled, userId, currentStart, onDateSelection, isAccessibility,
                    }}>
                      <Slots
                        planning={planningState}
                        userId={userId}
                        hoveredUserFromChild={setCurrentHoveredUser}
                        hoveredUserFromParent={hoveredUserFromParent}
                      />
                    </Context.Provider>
                  </div>
                  <RightOutlined className="text-2xl cursor-pointer lg:mt-[3.2rem] lg:ml-4 transition ease-in-out hover:scale-110" onClick={() => setCurrentStart(dayjs(currentStart).add(7, 'days').format())} />
                </div>
                <div className="lg:ml-10 lg:mb-4">
                  <div className="mt-6 flex flex-wrap space-r-2">
                    {tags.map((t) => renderTag(t, isAccessibility))}
                  </div>
                  <div className="status mt-2 flex">
                    {statuses.map(renderStatus)}
                  </div>
                  <div className="status mt-2 flex">
                    <Tag className="flex items-center" key="birthday">
                    🎂&nbsp;&nbsp;Anniversaire
                    </Tag>
                    <Tag className="flex items-center" key="uptooBirthday">
                    ✨&nbsp;&nbsp;Uptooversaire
                    </Tag>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        )
        : (
          <div className='w-full'>
            <div className='mt-4 flex justify-center'>Chargement du planning...</div>
            <div className='mt-4 flex justify-center'><Spin spinning={loading} /></div>
          </div>
        )
      }
    </div>
  )
}

HRDatePicker.propTypes = {
  planning: PropTypes.object.isRequired,
  getPlanning: PropTypes.func,
  userId: PropTypes.string,
  groupId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  showTeam: PropTypes.bool,
  disabled: PropTypes.bool,
  onDateSelection: PropTypes.func,
  currentStart: PropTypes.string.isRequired,
  setCurrentStart: PropTypes.func.isRequired,
  fullAccess: PropTypes.bool.isRequired,
}

export default HRDatePicker
