import { Button, Row, Tooltip } from 'antd'
import { EyeOutlined, SearchOutlined } from '@ant-design/icons'

import People from 'components/autocomplete/People'

export const searchIcon = (filtered) => <SearchOutlined className={filtered ? 'text-blue-400' : undefined} />

export const columnTitleIconStyle = { className: 'font-bold' }

export const renderColumnTitleIcon = (title, icon) => (
  <Tooltip title={title}>
    <Row align="middle" justify="center" className="text-base w-full">
      {icon}
    </Row>
  </Tooltip>
)

export const renderViewInNewTabButton = (url) => (
  <Tooltip title="Ouvrir dans un nouvel onglet" placement="left">
    <Button
      onClick={(e) => {
        e.stopPropagation()
        window.open(url)
      }}
      type="dashed"
      icon={<EyeOutlined />}
    />
  </Tooltip>
)

export const filterPerson = (props) => ({
  filterDropdown: function filterPersonDropdown({ setSelectedKeys, confirm }) {
    return (
      <People
        types={props.types || ['admin', 'developer']}
        style={{ width: '100%', padding: 8, ...props.style }}
        allowClear={typeof props.allowClear === 'boolean' ? props.allowClear : true}
        onSelect={(person) => {
          setSelectedKeys(person._id)
          confirm()
        }}
        onEmpty={() => {
          setSelectedKeys(null)
          confirm()
        }}
      />
    )
  },
  filterIcon: searchIcon,
})
