import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {
  Avatar, Popover, Select,
} from 'antd'
import {
  CalendarFilled, ControlOutlined, MailFilled, FlagFilled,
} from '@ant-design/icons'

import classNames from 'helpers/classNames'
import Api from 'services/api'

const { Option } = Select

const list = [
  { key: 'meeting', text: 'Meets Client' },
  { key: 'mission', text: 'Suivi mission' },
  { key: 'email', text: 'Emails' },
]

const eventInfos = (event) => (
  <>
    <div className="mb-6">
      <div className='font-bold'>
        {event.meetingTitle}
      </div>
    </div>
    <div className='flex items-center space-x-2'>
      <div className='font-bold'>Date :</div>
      <div className='font-semibold text-text-3'>{dayjs(event.date).format('DD/MM/YYYY')}</div>
    </div>
    <div className='flex items-center space-x-2'>
      <div className='font-bold'>Admin :</div>
      <Avatar.Group>
        {event.users?.length ? event.users?.map((user) => (
          <Avatar key={user._id} size="small" src={user.picture}>
            {user.firstName?.charAt(0).toUpperCase()}{user.lastName?.charAt(0).toUpperCase()}
          </Avatar>
        ))
          : <div className='font-semibold text-text-3'>Aucun utilisateur</div>
        }
      </Avatar.Group>
      {/* <span className='font-bold'>
        {event.users?.length && event.users?.length === 1
          ? `${event.users[0].firstName} ${event.users[0].lastName}`
          : event.users?.length && event.users?.length > 1
            ? `${event.users?.length} admins`
            : ''
        }
      </span> */}
    </div>
    <div className='flex items-center space-x-2'>
      <div className='font-bold'>Particip. :</div>
      <Avatar.Group>
        {event.attendees?.length
          ? event.attendees?.map((attendee) => (
            <Avatar key={attendee._id} size="small" src={attendee.picture}>
              {attendee.firstName?.charAt(0).toUpperCase()}{attendee.lastName?.charAt(0).toUpperCase()}
            </Avatar>
          ))
          : <div className='font-semibold text-text-3'>Aucun client</div>
        }
      </Avatar.Group>
      {/* <span className='font-bold'>
        {event.attendees?.length && event.attendees?.length === 1
          ? `${event.attendees[0].firstName} ${event.attendees[0].lastName}`
          : event.attendees?.length && event.attendees?.length > 1
            ? `${event.attendees?.length} participants`
            : ''
        }
      </span> */}
    </div>
  </>
)

const Event = ({ event, children }) => (
  <Popover
    className='p-0 min-w-fit m-0'
    placement="bottom"
    content={eventInfos(event)}>
    {children}
  </Popover>
)

const Timeline = ({ missionIds, tags }) => {
  const [selectedFilters, setSelectedFilters] = useState(['mission', 'meeting'])
  const [events, setEvents] = useState([])
  const [eventsState, setEventsState] = useState([])
  const [nextEvent, setNextEvent] = useState({})

  const eventTypes = events.length
    ? [...new Set(events?.map((event) => list.find((type) => type.key === event.type)))]
    : [{ key: 'mission', text: 'Suivi Mission' }]

  useEffect(() => {
    getTimeline()
  }, [missionIds])

  useEffect(() => {
    const filtered = events
      .filter((e) => selectedFilters.includes(e.type))
      .sort((a, b) => dayjs(a.date).isAfter(dayjs(b.date)))

    const n = filtered.findIndex((event) => dayjs(event.date).isAfter(dayjs(), 'day')) === -1
      ? filtered.length
      : filtered.findIndex((event) => dayjs(event.date).isAfter(dayjs(), 'day'))

    setNextEvent(filtered[n])
    setEventsState(filtered)
  }, [selectedFilters, events])

  const getTimeline = async () => {
    try {
      const response = await Api.axios.get('/v4/admin/missions/timeline', {
        params: {
          missions: missionIds,
        },
      })
      setEvents(response.timeline.meetings.data)
    } catch (e) {
      console.log('e', e)
    }
  }

  const renderIcon = (event) => {
    const classname = classNames(
      'text-2xs mb-1',
      dayjs().isBefore(dayjs(event.date)) ? 'text-text-2' : 'text-text-4',
    )

    if (event.type === 'meeting') {
      return (
        <CalendarFilled className={classname} />
      )
    }
    if (event.type === 'meeting') {
      return (
        <MailFilled className={classname} />
      )
    }
    if (event.type === 'mission') {
      return (
        <FlagFilled className={classname} />
      )
    }
    return false
  }

  const renderEvent = (event, i) => {
    // On calcul la durée de la timeline
    const start = eventsState[0].date

    const end = eventsState[eventsState.length - 1].date

    const total = dayjs(end).diff(dayjs(start), 'days')
    // On calcul le prorata de l'event
    const days = dayjs(event.date).diff(dayjs(eventsState[0].date), 'days')
    const prorata = Math.round((days / total) * 100)

    const getClassName = () => {
      if (i === 0) {
        return 'left-[0px] -translate-x-2'
      }
      if (i === eventsState.length - 1) {
        return 'right-[0px] translate-x-2'
      }
      return '-translate-x-2'
    }

    const getStyle = () => {
      if (i === 0) {
        return {}
      }
      if (i === eventsState.length - 1) {
        return {}
      }
      return {
        left: `calc(${prorata}%)`,
      }
    }

    const getColor = () => {
      if (dayjs().isBefore(dayjs(event.date))) {
        return 'bg-red-500'
      }
      return 'bg-bg-layout'
    }

    return (
      <Event key={i} event={event}>
        <div
          className={classNames(
            'group absolute -bottom-[5px] h-2 w-2 cursor-pointer',
            'flex flex-col items-center justify-end',
            getClassName(),
          )}
          style={getStyle()}
        >
          {renderIcon(event)}
          <div className="relative">
            {dayjs().isBefore(dayjs(event.date)) && (
              <div className={classNames(
                'absolute h-2 w-2 rounded-full ml-[3px] mt-[4px] animate-ping',
                getColor(event.tags),
              )} />
            )}
            <div className={classNames(
              'h-2 w-2 rounded-full border-[3px] border-bg-container box-content mt-[1px]',
              getColor(event.tags),
            )} />
          </div>
          {i === 0 && (
            <div className={classNames(
              'absolute left-0 top-4 border border-border',
              'px-2 py-1 rounded',
              'text-xs text-text-3 font-bold',
            )}>
              {dayjs(event.date).format('DD/MM/YYYY')}
            </div>
          )}
          {i > 0 && i === (eventsState.length - 1) && (
            <div className={classNames(
              'absolute right-0 top-4 border border-border',
              'px-2 py-1 rounded',
              'text-xs text-text-3 font-bold',
            )}>
              {dayjs(event.date).format('DD/MM/YYYY')}
            </div>
          )}
        </div>
      </Event>
    )
  }

  const renderEvents = () => {
    // On calcul la durée de la timeline
    const start = eventsState[0].date
    const end = eventsState[eventsState.length - 1].date

    let now
    let next

    if (dayjs().isBetween(dayjs(start), dayjs(end))) {
      const total = dayjs(end).diff(dayjs(start), 'days')

      // On calcul le prorata de l'event
      const days = dayjs().diff(dayjs(eventsState[0].date), 'days')
      const prorata = Math.round((days / total) * 100)

      next = (
        <div
          className="bg-primary-hover rounded-lg h-1 -mt-1"
          style={{ marginLeft: `calc(${prorata}%)` }}
        />
      )

      now = (
        <div
          className={classNames(
            'group absolute -top-[8px] h-2 w-2 flex flex-col items-center justify-center -translate-x-2',
          )}
          style={{
            left: `calc(${prorata}%)`,
          }}
        >
          <div className="flex flex-col items-center justify-center">
            <div
              className='w-4 h-2 bg-text-2 mb-1'
              style={{ clipPath: 'polygon(0% 0%, 100% 0%, 50% 100%)' }}
            />
            <div className="h-5 w-1 bg-primary-active rounded-full border-bg-container border-[3px] box-content" />
          </div>
        </div>
      )
    }

    return (
      <div className="relative w-full">
        <div
          className={classNames(
            'rounded-lg h-1 w-full',
            dayjs().isBefore(dayjs(eventsState[0].date)) ? 'bg-primary-hover' : 'bg-primary-bg',
          )}
        />
        {next}
        {eventsState.map(renderEvent)}
        {now}
      </div>
    )
  }

  return (
    <div className='bg-white border border-[#D9D9D9] rounded-lg'>
      <div className='flex justify-between items-center bg-[#F5F5F5] py-4 px-6'>
        <div className='font-bold'>Timeline</div>
        {!tags.includes('recrutement')
          ? (
            <div>
              <span className='mr-2'>
            Filtres
              </span>
              <Select
              // className="w-96"
                allowClear
                mode='multiple'
                placeholder={(
                  <div className='flex items-center space-x-2'>
                    <ControlOutlined />
                    <span>Filtres</span>
                  </div>
                )}
                onChange={setSelectedFilters}
                suffixIcon={null}
                value={selectedFilters}
              >
                {eventTypes.map((el) => <Option key={el?.key} value={el?.key}>{el?.text}</Option>)}
              </Select>
            </div>
          )
          : null
        }
      </div>
      <div className="py-4 px-6 flex flex-row space-x-8 h-full">
        {eventsState.length > 0 ? (
          <>
            <div className='flex-1 p-4 py-8'>
              <div className="flex flex-col justify-between h-full">
                <div className="flex-1 flex flex-col justify-between">
                  <div className="flex-1 flex items-center">
                    {renderEvents()}
                  </div>
                </div>
              </div>
            </div>
            {!tags.includes('recrutement') && (
              <div className="w-1/3 flex flex-col bg-bg-layout rounded p-4">
                {nextEvent?.meetingTitle
                  ? eventInfos(nextEvent)
                  : (
                    <div className="w-full flex-1 flex items-center justify-center text-text-4">
                      Aucun événement à venir
                    </div>
                  )}
              </div>
            ) }
          </>
        ) : (
          <div className="w-full flex justify-center text-text-3">
            Aucun évènement disponible
          </div>
        )}
      </div >
    </div>
  )
}

Timeline.propTypes = {
  missionIds: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  title: PropTypes.object,
  className: PropTypes.string,
  tags: PropTypes.array,
}

Timeline.defaultProps = {
  missionIds: [],
  compact: false,
  title: {},
  stepClass: '',
  className: '',
  tags: [],
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
  children: PropTypes.object,
}

Event.defaultProps = {
  event: {},
  children: '',
}

export default Timeline
