import PropTypes from 'prop-types'
import { Row, Col, Pagination as PaginationAntd } from 'antd'

function Pagination({ pagination, paginate }) {
  const paginationFormatted = {
    $currentIndex: pagination.currentIndex || 1,
    $itemsPerPage: pagination.itemsPerPage || 25,
    $totalItems: pagination.totalItems || 0,
  }

  return (
    <Row justify="center" align="middle">
      <Col className="hidden md:block">
        <PaginationAntd
          onChange={(currentIndex, itemsPerPage) => paginate({
            currentIndex: currentIndex || paginationFormatted.$currentIndex,
            itemsPerPage: itemsPerPage || paginationFormatted.$itemsPerPage,
          })}
          {...pagination}

        />
      </Col>
      <Col className="sm:hidden">
        <PaginationAntd
          simple
          onChange={(currentIndex, itemsPerPage) => paginate({
            currentIndex: currentIndex || paginationFormatted.$currentIndex,
            itemsPerPage: itemsPerPage || paginationFormatted.$itemsPerPage,
          })}
          {...pagination}
        />
      </Col>
    </Row>
  )
}

Pagination.propTypes = {
  paginate: PropTypes.func.isRequired,
  pagination: PropTypes.object,
}

Pagination.defaultProps = {
  pagination: {},
}

export default Pagination
