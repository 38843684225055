import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Switch } from 'antd'

import Api from 'services/api'
import ProfilForm from './Profil'
import RemunerationForm from './Remuneration'
import LanguagesForm from './Languages'
import KeywordsForm from './Keywords'
import LocalisationForm from './Localisation'
import ProfilCoForm from './ProfilCo'

const FormContainer = ({
  lists, initialValues = {}, combine, unsaved, feasabilityId, isB, setDatas, datas, isNotPDF, setIsNotPDF,
}) => {
  const [form] = Form.useForm()
  const [template, setTemplate] = useState({})

  useEffect(() => {
    getTemplate()
    if (!feasabilityId) {
      handleSearch()
    }
  }, [])

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(initialValues)
  }, [initialValues])

  const getTemplate = async () => {
    try {
      const { template: data } = await Api.axios.get('/v4/admin/templates/6520028b272842b3fa689b88')

      setTemplate(data.data.steps.find((s) => s.slug === 'nuances'))
    } catch (err) {
      // console.log('err', err)
    }
  }

  const handleSearch = async () => {
    try {
      const values = await form.validateFields()

      const forcedValues = {
        ...Object.fromEntries(Object.entries(values).filter(([key, object]) => object.force ||
          (key.includes('salary') && values.salary?.force) ||
         (key.includes('language') && values.languages?.force) ||
         (key === 'salesProfiles' && Object.values(object)?.some((v) => v.force && v.value)) ||
         (key === 'keywords' && Object.values(object)?.some((v) => v.force)))),
      }

      const filtered = {}
      if (Object.keys(forcedValues.salesProfiles || {}).length) {
        Object.entries(forcedValues.salesProfiles).forEach(([k, v]) => {
          if (v.force && v.value?.length) {
            filtered[k] = {
              ...v,
              value: [v.value?.[0] || 0, (v.value?.[1] || 80) + 20],
            }
          }
        })
      }

      const filters = {
        ...forcedValues,
        studyLevel: forcedValues.studyLevels,
        experienceLevel: forcedValues.experienceLevels,
        salesProfiles: filtered,
        keywords: [...Object.values(forcedValues.keywords || {}).map((k) => ({ ...k, position: 'lastResume', id: 'keywords-8825123107070558' }))],
        languages: {
          value: [
            ...Object.values(Object.fromEntries(Object.entries(forcedValues)?.filter(([key, value]) => key.includes('language') && value.value))),
          ],
          force: values.languages?.force,
        },
        notHired: true,
        notAssessment: true,
        _source: 'NoSource',
      }
      filters.languages.value = filters.languages?.value?.reduce((acc, item) => {
        const findLevel = lists.levelLanguages[item.value - 1]
        if (findLevel) acc.push({ title: item.title, value: findLevel.title })
        return acc
      }, [])

      // searching...
      const { results } = await Api.axios.post('v4/admin/people/feasibility', { $filter: filters })
      combine({ ...values, totalProfils: results.totalItems })
      unsaved()
      // }
    } catch (e) {
      // console.log('err', e)
    }
  }

  const handleIsNotPDF = async (e) => {
    setIsNotPDF(e)
    setDatas([datas[0], { ...datas?.[1], isNotPDF: e }])
    unsaved()
  }

  return (
    <Form
      form={form}
      colon={false}
      layout='vertical'
      initialValues={{ ...initialValues, isNotPDF }}
      onValuesChange={(props) => ![
        'keywords',
        'salary',
        'salaryFixed',
        'functions',
        'sectors',
        'studyLevels',
        'experienceLevels',
        'locations',
        'departments',
        'salesProfiles',
        // 'isNotPDF',
      ].includes(...Object.keys(props)) && handleSearch()}
    >
      <>
        {isB && (
          <div className='w-full mb-4 flex items-center space-x-2'>
            <p>Ne pas inclure dans le PDF :</p>
            {/* <Item name='isNotPDF' label=' ' className='p-0'> */}
            <Switch
              size='small'
              checked={isNotPDF}
              onChange={(e) => handleIsNotPDF(e)}
              className='mb-1.5'
            />
            {/* </Item> */}
          </div>
        ) }

        <div className='grid grid-cols-1 md:grid-cols-3 space-x-8 mb-8'>
          <ProfilForm lists={lists} handleSearch={handleSearch} />
          <RemunerationForm handleSearch={handleSearch} />
          <LanguagesForm lists={lists} handleSearch={handleSearch} />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 space-x-8 mb-8'>
          <Card className='h-full'>
            <KeywordsForm handleSearch={handleSearch} />
            <LocalisationForm lists={lists} handleSearch={handleSearch} />
          </Card>
          <div className='col-span-2 h-full'>
            <ProfilCoForm
              handleSearch={handleSearch}
              template={template}
              form={form}
            />
          </div>
        </div>
      </>
    </Form >
  )
}

FormContainer.propTypes = {
  initialValues: PropTypes.object.isRequired,
  combine: PropTypes.func.isRequired,
  lists: PropTypes.object.isRequired,
  unsaved: PropTypes.func.isRequired,
  feasabilityId: PropTypes.string,
  isB: PropTypes.bool.isRequired,
  setDatas: PropTypes.func,
  datas: PropTypes.array,
  isNotPDF: PropTypes.bool,
  setIsNotPDF: PropTypes.func,
}

export default FormContainer
