import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  Layout, Row, Typography, Button, Col, Switch,
} from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import Api from 'services/api'
import List from './List'

const { Content } = Layout
const { Title } = Typography

const StudyContainer = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const Auth = useSelector((state) => ({ user: state.Auth.user }))
  const [controlled, setControlled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [feasabilitiesState, setFeasabilities] = useState({ data: [], $pagination: {}})
  const [params, setParams] = useState({
    $filter: { _creator: Auth.user._id },
    $populate: [{
      path: '_creator',
      select: 'firstName lastName picture',
    }, {
      path: '_company',
      select: 'name',
    }],
    $sort: '-_createdAt',
    $pagination: { itemsPerPage: 25 },
  })

  useEffect(() => {
    getFeasabilities()
  }, [params])

  const getFeasabilities = async () => {
    try {
      setLoading(true)
      const response = await Api.axios.get('v4/admin/feasibilities', { params })
      setFeasabilities({ feasibilities: response.feasibilities, $pagination: response.$pagination })
    } finally {
      setLoading(false)
    }
  }

  const deleteFeasability = async (feasabilityId) => {
    try {
      await Api.axios.delete(`v4/admin/feasibilities/${feasabilityId}`)
    } finally {
      getFeasabilities()
    }
  }

  useEffect(() => {
    if (controlled) {
      setParams({
        ...params,
        $filter: {
          ...params.$filter,
          _creator: Auth.user._id,
        },
      })
    } else if (!params.$filter?._creator || params.$filter?._creator === Auth.user._id) {
      setParams({
        ...params,
        $filter: {
          ...params.$filter,
          _creator: undefined,
        },
      })
    }
  }, [controlled])

  const filterFeasabilities = (newFilter, newSorter, newPagination) => {
    const newRequest = {
      ...params,
      $filter: {
        ...params.$filter,
        ...newFilter,
      },
      $filterGroup: { ...params.$filterGroup },
      $sort: newSorter || params.$sort,
      $pagination: newPagination,
    }
    setParams(newRequest)
    if (newFilter._creator) setControlled(false)
  }

  const extractCSV = async () => {
    try {
      const { csv } = await Api.axios.post('v4/admin/feasibilities/csv', { params })
      if (csv) {
        window.location.href = csv
      }
    } catch (err) {
      // console.log('err', err)
    }
  }

  return (
    <div className="flex-col">
      <Helmet>
        <title>Étude de faisabilité</title>
      </Helmet>
      <div>
        <Content>
          <Row className="flex items-center justify-between mb-5 border-b mx-2 pb-2">
            <Col className='flex items-center space-x-4'>
              <Title level={4} className='mb-0'>
                Étude de faisabilité
              </Title>
              <Switch
                checkedChildren="Moi"
                unCheckedChildren="All"
                defaultChecked
                checked={controlled}
                onChange={(e) => setControlled(e)}
              />
            </Col>
            <Col>
              <Button onClick={extractCSV}>
                CSV
              </Button>
              <Button
                className='ml-4'
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => history.push(`${path}/new`)}
              >
                Nouvelle étude
              </Button>
            </Col>
          </Row>
          <List
            feasabilities={feasabilitiesState}
            loading={loading}
            filterFeasabilities={filterFeasabilities}
            deleteFeasability={deleteFeasability}
          />
        </Content>
      </div>
    </div >
  )
}

export default StudyContainer
