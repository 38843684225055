import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Form, Input, Button, Layout, Row,
} from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import DarkMode from 'app/layout/header/DarkMode'
import { loginUser, authenticate } from '../../store/actions'
import { checkAuthentication } from '../../store/middlewares'
import Authenticator from './Authenticator'

const { Content } = Layout
const { Item } = Form

const Login = () => {
  const dispatch = useDispatch()
  const Auth = useSelector((state) => ({
    isLoading: state.Auth.isLoading,
    authenticator: state.Auth.authenticator,
  }))
  const [form] = Form.useForm()
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' }}
  const [email, setEmail] = useState('')

  useEffect(() => {
    checkAuthentication(from)
  }, [])

  const onFinish = (values) => {
    setEmail(String(values.email))
    return dispatch(loginUser(values, from))
  }

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo)

  return (
    <div>
      <div>
        <div className="absolute top-10 right-10">
          {/* <DarkMode /> */}
        </div>
        <Row justify="center" align="middle" className="h-screen px-4">
          <div className="w-full" style={{ maxWidth: 370 }}>
            <div className="flex justify-center pb-8">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-auto" viewBox="0 0 571 186">
                <g id="logo-uptoo">
                  <path className="text-text-4 fill-current" d="M543.51,88.74q0-15.58-10-26.25a34,34,0,0,0-49.48,0q-10,10.66-10,26.25t9.92,26a34.56,34.56,0,0,0,49.6-.12Q543.5,104.11,543.51,88.74Zm-94-.23q-.45-24.08,17.22-41.61t41.85-17.09Q533,29.36,550.8,47t17.33,41.5q.47,24.08-17.33,41.73t-42.18,17.2q-24.18.45-41.85-17.31T449.55,88.51Zm-35.42.23q0-15.58-10-26.25a34,34,0,0,0-49.48,0q-10,10.66-10,26.25t9.92,26a34.56,34.56,0,0,0,49.6-.12Q414.14,104.11,414.13,88.74Zm-93.95-.23q-.45-24.08,17.22-41.61t41.84-17.09q24.4-.45,42.19,17.2t17.33,41.5q.47,24.08-17.33,41.73t-42.19,17.2q-24.17.45-41.84-17.31T320.18,88.51Zm-5.32,36v20.41A98.57,98.57,0,0,1,295,147.44q-19.61,0-30.44-9.86t-10.83-29.35v-55H235.51V32.34h18.24V7.07L277.92.93V32.34h33.29V53.2H277.92v53q0,10.55,5.36,15T300,125.66Q303.91,125.66,314.86,124.51Z"/>
                  <path className="text-text-4 fill-current" d="M195.18,114.42Q205,104.16,205,88.21t-9.79-26.32a32.12,32.12,0,0,0-24.36-10.37,32.88,32.88,0,0,0-23.9,9.57q-9.79,9.57-9.8,26.89,0,17.55,9.8,27.12a32.85,32.85,0,0,0,23.9,9.58A32.28,32.28,0,0,0,195.18,114.42ZM229.57,88q0,25.08-15.6,41.82t-39.28,16.75q-23.22,0-37.34-16.18v54.7h-23.9V41l22.31-5.43V47.87q12.75-18.22,38.93-18.23,23.69,0,39.28,16.75T229.57,88ZM2.86,97.1v-29L26.53,62V98.92q0,11.4,7.06,18.46t18.22,7.07q10.69,0,17.64-7.07t7-18.46V49.77l23.9-6.11V97.1q0,22.11-13.77,35.78T51.81,146.55q-21.41,0-35.18-13.56T2.86,97.1Z"/>
                </g>
              </svg>
            </div>
            {Auth.authenticator
              ? (
                <Authenticator
                  action={(data) => dispatch(authenticate({ ...data, email, authenticator: Auth.authenticator }, from))}
                  loading={Auth.isLoading}
                />
              )
              : (
                <div className="bg-bg-container rounded ring-black ring-1 ring-opacity-5 shadow-lg rounded-t">
                  <img
                    src="https://uptoo.fr/assets/galaxy/galaxy-v2-5%20copie.png"
                    className="rounded-t mx-auto w-full"
                  />
                  <div className="bg-main-bg site-layout-content rounded-b">
                    <div className="p-8">
                      <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        requiredMark={false}
                      >
                        <Item name="email" label="Adresse e-mail" autoComplete="off" required>
                          <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            disabled={Auth.isLoading}
                          />
                        </Item>
                        <Item
                          name="password"
                          label="Mot de passe"
                          rules={[{
                            required: true,
                            min: 1,
                            message: 'Champ requis',
                          }]}
                        >
                          <Input.Password
                            disabled={Auth.isLoading}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                          />
                        </Item>
                        <Button
                          block
                          type="primary"
                          htmlType="submit"
                          loading={Auth.isLoading}
                        >
                          Connexion
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </Row>
      </div>
    </div>
  )
}

export default Login
