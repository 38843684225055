import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {
  Button, Col, Divider, Form, Modal, Row, Spin, Switch,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import Api from 'services/api'
import FormRequest from './form'

const New = ({
  firstSlot, lastSlot, resetSlots, collab, getUser, getPlanning, fullAccess,
}) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [offices, setOffices] = useState([])
  const [isVacation, setIsVacation] = useState(false)
  const [availabilities, setAvailabilities] = useState([])
  const [alert, setAlert] = useState({})

  useEffect(() => {
    fetchOffices()
    if (firstSlot || lastSlot) {
      form.setFieldsValue({
        firstSlotAt: firstSlot,
        lastSlotAt: lastSlot,
      })
    }
  }, [])

  useEffect(() => {
    if (firstSlot && lastSlot) {
      setVisible(true)
    }
  }, [firstSlot, lastSlot])

  useEffect(() => {
    form.resetFields()
  }, [visible])

  const fetchOffices = async () => {
    const res = await Api.axios.get('/v4/admin/offices', {
      params: {
        $filter: {
          isUptoo: true,
        },
        $populate: [
          {
            path: '_zones',
            select: '_id',
            match: { isActive: true },
          },
        ],
      },
    })
    setOffices([
      { _id: 'remote', title: 'Home Office' },
      ...res.offices,
      { _id: 'school', title: 'École' },
    ])
  }

  const fetchAvailabilities = async (data) => {
    try {
      let zonesAvailabilities = []
      const values = await form.validateFields()

      if ((values.office && !['remote', 'school'].includes(values.office)) || data.favoriteZone) {
        const body = {
          firstSlotAt: data?.exactFirstSlotAt || values.firstSlotAt,
          lastSlotAt: data?.exactLastSlotAt || values.lastSlotAt,
        }
        if (values.office) {
          body._office = values.office
        }
        if (data.favoriteZone) {
          body.favoriteZone = data.favoriteZone
        }
        const { zones } = await Api.axios.post('/v4/admin/hr/planning/availabilities', body)
        zonesAvailabilities = zones || []
      }

      setAvailabilities(zonesAvailabilities)
    } catch {
      setAvailabilities([])
    }
  }

  const submitVacation = async (data) => {
    try {
      setSubmitLoading(true)
      const values = await form.validateFields()
      const exactDates = {
        exactFirstSlotAt: values.firstSlotAt ? dayjs(values.firstSlotAt).startOf('day').add(dayjs(firstSlot).format('a') === 'am' ? 0 : 12, 'hours') : null,
        exactLastSlotAt: values.lastSlotAt ? dayjs(values.lastSlotAt).startOf('day').add(dayjs(lastSlot).format('a') === 'am' ? 0 : 12, 'hours') : null,
      }

      const postData = {
        ...values,
        userId: collab._id,
        firstSlotAt: data?.exactFirstSlotAt || exactDates.exactFirstSlotAt,
        lastSlotAt: data?.exactLastSlotAt || exactDates.exactLastSlotAt,
        type: isVacation
          ? values.vacationType
          : ['remote', 'school'].includes(values.office)
            ? values.office
            : 'office',
      }
      if (postData.type === 'office') {
        postData._zone = data.zoneId
      }
      if (fullAccess) {
        await Api.axios.post('/v4/admin/hr/force/force', postData)
      } else if (isVacation) {
        await Api.axios.post('/v4/admin/hr/requests', postData)
      } else {
        await Api.axios.post('/v4/admin/hr/slots', postData)
      }
      getPlanning()
      resetSlots()
      setVisible(false)
    } finally {
      setSubmitLoading(false)
    }
  }

  const checkVacation = async ({ exactFirstSlotAt, exactLastSlotAt }) => {
    try {
      setSubmitLoading(true)
      const values = await form.validateFields()

      if (['cp', 'rtt'].includes(values.vacationType)) {
        const postData = {
          collab: collab._id,
          firstSlotAt: exactFirstSlotAt || values.firstSlotAt,
          lastSlotAt: exactLastSlotAt || values.lastSlotAt,
          type: isVacation
            ? values.vacationType
            : ['remote', 'school'].includes(values.office)
              ? values.office
              : 'office',
        }
        let res
        if (fullAccess) {
          res = await Api.axios.post('/v4/admin/hr/force/check', postData)
        } else {
          res = await Api.axios.post('/v4/admin/hr/requests/check', postData)
        }
        setAlert(res.alert)
      } else if (values.vacationType === 'family') {
        const res = await Api.axios.post('/v4/admin/hr/requests/check', {
          collab: collab._id,
          firstSlotAt: exactFirstSlotAt || values.firstSlotAt,
          lastSlotAt: exactLastSlotAt || values.lastSlotAt,
          type: isVacation
            ? values.vacationType
            : ['remote', 'school'].includes(values.office)
              ? values.office
              : 'office',
        })
        setAlert(res.alert)
      } else {
        setAlert({})
      }
    } finally {
      setSubmitLoading(false)
    }
  }

  const removeLocationSlots = async (data) => {
    try {
      setSubmitLoading(true)
      const values = await form.validateFields()
      const exactDates = {
        exactFirstSlotAt: values.firstSlotAt ? dayjs(values.firstSlotAt).startOf('day').add(dayjs(firstSlot).format('a') === 'am' ? 0 : 12, 'hours') : null,
        exactLastSlotAt: values.lastSlotAt ? dayjs(values.lastSlotAt).startOf('day').add(dayjs(lastSlot).format('a') === 'am' ? 0 : 12, 'hours') : null,
      }

      const postData = {
        _user: collab._id,
        firstSlotAt: data?.exactFirstSlotAt || exactDates.exactFirstSlotAt,
        lastSlotAt: data?.exactLastSlotAt || exactDates.exactLastSlotAt,
      }
      if (fullAccess) {
        postData.force = true
      }
      await Api.axios.post('/v4/admin/hr/slots/remove', postData)

      getPlanning()
      resetSlots()
      setVisible(false)
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <>
      <Button type='primary' icon={<PlusOutlined />} onClick={() => setVisible(true)}>
        Nouvelle demande
      </Button>
      <Modal
        forceRender
        destroyOnClose
        title='Nouvelle demande'
        open={visible}
        onCancel={() => {
          resetSlots()
          setVisible(false)
          setAlert({})
          setIsVacation(false)
          form.resetFields()
        }}
        width={900}
        footer={null}
      >
        <Spin spinning={submitLoading}>
          <Row>
            <Col>
              <label className="font-bold">
                Mode planning
                <Switch
                  checked={isVacation}
                  onClick={() => setIsVacation(!isVacation)}
                  className="mx-3"
                />
                Mode congé
              </label>
            </Col>
            <Divider />
            <FormRequest
              form={form}
              isVacation={isVacation}
              firstSlot={firstSlot}
              lastSlot={lastSlot}
              offices={offices}
              zones={availabilities}
              setIsVacation={setIsVacation}
              submitVacation={submitVacation}
              checkVacation={checkVacation}
              fetchAvailabilities={fetchAvailabilities}
              fullAccess={fullAccess}
              loading={submitLoading}
              alert={alert}
              collab={collab}
              getUser={getUser}
              removeLocationSlots={removeLocationSlots}
              visible={visible}
            />
          </Row>
        </Spin>
      </Modal>
    </>
  )
}

New.propTypes = {
  firstSlot: PropTypes.string,
  lastSlot: PropTypes.string,
  resetSlots: PropTypes.func.isRequired,
  collab: PropTypes.object,
  getPlanning: PropTypes.func.isRequired,
  fullAccess: PropTypes.bool.isRequired,
  getUser: PropTypes.func.isRequired,
}

export default New
