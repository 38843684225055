import { useContext } from 'react'
import { Button, Tooltip } from 'antd'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'

import Context from '../../Context'
import NewNote from './New'
import EditNote from './Edit'

const Note = () => {
  const {
    setNotes,
    noteSelected,
    setNoteSelected,
    newNote,
    setNewComment,
    emptyEditorContent,
  } = useContext(Context)

  let item
  if (newNote._id && noteSelected?.type === 'new') {
    item = <NewNote />
  }
  if (noteSelected && noteSelected.type !== 'new') {
    item = <EditNote />
  }
  if (item) {
    return (
      <div className='relative w-full h-full'>
        <div className='mb-6'>
          <Tooltip title='Liste'>
            <Button
              onClick={() => {
                const tmp = noteSelected
                if (noteSelected?.type === 'new') {
                  setNotes((prev) => {
                    const newNotes = [...prev.data]
                    const i = newNotes.findIndex((n) => n.type === 'new')
                    if (i > -1) {
                      if (newNote._template) {
                        newNotes[i]._template = {
                          ...tmp._template,
                          _id: newNote._template?._id,
                        }
                      }
                      newNotes[i].trust = newNote.trust
                      newNotes[i].content = newNote.content
                    }
                    return {
                      ...prev,
                      data: newNotes,
                    }
                  })
                }
                setNoteSelected(null)
                setNewComment(emptyEditorContent)
              }}
              icon={noteSelected.type === 'new' ? <CloseOutlined /> : <MenuOutlined />}
            />
          </Tooltip>
        </div>
        {item}
      </div>
    )
  }
  return null
}

export default Note
