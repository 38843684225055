import { useState } from 'react'
import PropTypes from 'prop-types'

import classNames from 'helpers/classNames'
import Slot from './Slot'

const Slots = ({
  planning, userId, hoveredUserFromChild, hoveredUserFromParent,
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState([])
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isMouseOverActive, setIsMouseOverActive] = useState(false)
  const statesForSlotComponent = {
    setStates: { setSelectedDateRange, setIsMouseDown, setIsMouseOverActive },
    getStates: { selectedDateRange, isMouseDown, isMouseOverActive },
  }

  const renderPlanning = (data) => data.map((u, index) => {
    const isUserSlot = u.user._id === userId

    return (
      <div
        className={classNames(
          'flex w-full',
          !isUserSlot && 'hidden lg:flex hover:brightness-95',
          hoveredUserFromParent === u.user._id && 'brightness-95',
        )}
        key={index}
        onMouseOver={() => hoveredUserFromChild(u.user._id)}
      >
        {u.dates.map((day, dayIndex, dates) => (
          <div key={dayIndex + u.user._id}>
            <Slot
              parentStates={statesForSlotComponent}
              dayIndex={dayIndex}
              day={day}
              isUserSlot={isUserSlot}
              yesterday={dates[dayIndex - 1]}
              tomorrow={dates[dayIndex + 1]}
            />
          </div>
        ))}
      </div>
    )
  })

  return (
    <div onMouseLeave={() => hoveredUserFromChild(null)}>
      {renderPlanning(planning)}
    </div>
  )
}

Slots.propTypes = {
  planning: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  hoveredUserFromChild: PropTypes.func.isRequired,
  hoveredUserFromParent: PropTypes.string,
}

export default Slots
