import Editor from '@uptoo/slate-editor/dist'
import '@uptoo/slate-editor/dist/css/style.css'
import PropTypes from 'prop-types'

import classNames from 'utils/classNames'

const AntEditor = ({
  form, className, placeholder, ...props
}) => {
  const classes = [
    'p-1 bg-bg-container rounded',
    'border border-border',
    'hover:border-primary-border-hover',
    'focus-within:border-primary-border focus-within:ring-2 focus-within:ring-blue-50',
    className,
  ]

  return (
    <div className={classNames(classes.join(' '))}>
      <Editor
        {...props}
        placeholder={placeholder}
      />
    </div>
  )
}

AntEditor.propTypes = {
  form: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

AntEditor.defaultProps = {
  className: '',
  placeholder: '',
}

export default AntEditor
